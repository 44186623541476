// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

var ready = (callback) => {
    if (document.readyState != "loading") callback();
    else document.addEventListener("DOMContentLoaded", callback);
}

ready(() => {
    var body = document.querySelector("body");
    var backgroundImage = document.querySelector(".background-image");
    backgroundImage.style.backgroundImage = "url('" + coverSrc + "')"
    backgroundImage.style.backgroundPosition = "center center"
    backgroundImage.style.backgroundRepeat = "no-repeat"
    backgroundImage.style.backgroundSize = "cover"

    if(!body.classList.contains("landing")) {
        backgroundImage.style.filter = "blur(48px)"
    }
});